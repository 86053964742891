import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'

import { Page, Header, Menu, MenuItem, Footer } from './components'
import { About, Contact, Work, Bank, NotFound } from './pages'
import tracker from './tracking'

const RedirectToCV = () => {
  React.useEffect(() => {
    window.location.href = '/cv-misera.pdf';
  }, []);
  return null;
};
class App extends React.Component {
  componentDidMount() {
    tracker.init(this.props.token)
  }
  render() {
    return (
      <Page>
        <header>
          <Header>Thomas Misera</Header>
        </header>
        <nav>
          <Menu>
            <MenuItem to='/' exact activeClassName='active'>about</MenuItem>
            <MenuItem to='/work' activeClassName='active'>work</MenuItem>
            <MenuItem to='/contact' activeClassName='active'>contact</MenuItem>
          </Menu>
        </nav>
        <main>
          <Switch>
            <Route exact path="/" component={About}/>
            <Route path="/work" component={Work}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/bank" component={Bank}/>
            <Route exact path="/cv" component={RedirectToCV} />
            <Route path="/*" component={NotFound}/>
          </Switch>
        </main>
        <footer>
          <Footer />
        </footer>
      </Page>
    );
  }
}

export default App;
