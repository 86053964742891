import React from 'react';
import styled from 'styled-components'

import { Content, Headline, InternalLink } from '../components'
import { ImageLink } from '../components/ImageLink'
import tracker from '../tracking'

const TechnologiesContainer = styled.div`
  font-size: 1rem;
  & h3 {
    font-size: 1.2rem;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;

  & ul { 
    padding: 1rem;
    list-style-type: circle;
  }
`

const Column = styled.div`
  flex-grow: 1;
`
const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
  & > * {
    padding-bottom: 2.5rem;
  }
  & img {
    max-height: 2rem;
  }
`

const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  line-height: 1.7rem;
`

class Work extends React.Component {
  componentDidMount() {
    tracker.onPageChange('work')
  }
  render() {
    return (
      <Content>
        <Headline>my work</Headline>
        <Paragraph>
          These are some of the technologies I used
        </Paragraph>
        <TechnologiesContainer>
  <Column>
    <h3>Languages</h3>
    <ul>
      <li>TypeScript</li>
      <li>HTML, CSS, JSX</li>
      <li>Python</li>
      <li>PHP</li>
      <li>SQL</li>
      <li>Bash</li>
    </ul>
  </Column>

  <Column>
    <h3>Libraries</h3>
    <ul>
      <li>React</li>
      <li>Redux</li>
      <li>MobX</li>
      <li>GraphQL</li>
      <li>Apollo</li>
      <li>Express</li>
      <li>Angular</li>
      <li>Flask</li>
      <li>TypeORM</li>
    </ul>
  </Column>

  <Column>
    <h3>Tools</h3>
    <ul>
      <li>Docker</li>
      <li>Kubernetes</li>
      <li>Terraform</li>
      <li>AWS (EKS, S3, EC2)</li>
      <li>Git</li>
      <li>GitLab CI</li>
      <li>Jenkins</li>
      <li>Webpack</li>
      <li>Yarn</li>
    </ul>
  </Column>
</TechnologiesContainer>


        <Paragraph>to build software for these companies</Paragraph>
        <Logos>
          <ImageLink 
            href="https://www.audi.de"
            img="audi.png"
          />
          <ImageLink 
            href="https://www.telekom.de"
            img="telekom.gif"
          />
          <ImageLink 
            href="https://www.rewe.de"
            img="rewe.png"
          />
          <ImageLink 
            href="https://www.heuteshow.de"
            img="heuteshow.png"Content
          />
          <ImageLink 
            href="https://www.fresenius.de"
            img="fresenius.png"
          />
          <ImageLink 
            href="https://www.axa.de"
            img="axa.png"
          />
          <ImageLink 
            href="https://www.hdi.de"
            img="hdi.png"
          />
          <ImageLink 
            href="https://www.postbank.de"
            img="postbank.png"
          />
          <ImageLink 
            href="https://www.hansgrohe.de"
            img="hansgrohe.png"
          />
          <ImageLink 
            href="https://www.coop.ch"
            img="coop.png"
          />
          <ImageLink 
            href="https://www.microspot.ch"
            img="microspot.png"
          />
          <ImageLink 
            href="https://www.interdiscount.ch"
            img="interdiscount.png"
          />
          <ImageLink 
            href="https://www.lekkerland.de"
            img="lekkerland.png"
          />
          <ImageLink 
            href="https://www.metro.de"
            img="metro.jpg"
          />
        </Logos>
        <Paragraph>
          {'Feel free to '}
          <InternalLink to="/contact">contact</InternalLink>
          {' me, I\'m always looking for new opportunities.'}
        </Paragraph>
      </Content>
    );
  }
}

export default Work;