import React from 'react';

import { Content, Headline, Paragraph } from '../components'
import { Link } from '../components/Link'
import { HoverImage } from '../components/HoverImage'
import tracker from '../tracking'


class About extends React.Component {
  componentDidMount() {
    tracker.onPageChange('about')
  }
  render() {
    return (
      <Content>
        <Headline>about me</Headline>
        <Paragraph>
          {'Hey! My name is Thomas and I live in '}
          <Link href="https://en.wikipedia.org/wiki/Aachen" external styled>Aachen</Link>
          {', Germany.'}
        </Paragraph>
        <Paragraph>
          <HoverImage />
          {"I'm a "}
          <strong>software engineer with over a decade of experience</strong>
          {" building scalable web and mobile applications: from fast-moving prototypes to production-grade platforms. "}
          {"I've led teams, shaped infrastructure, and written code across the stack, with a current focus on "}
          <em>backend systems, cloud-native architecture, and developer experience.</em>
          {" I care deeply about clean design, intuitive UX, and maintainable code "}&ndash;{" whether I’m working in TypeScript, React, or Terraform."}
        </Paragraph>
        <Paragraph>
          {'I was a student of '}
          <Link href="http://en.wikipedia.org/wiki/Knowledge_engineering" external styled>Knowledge Engineering</Link>
          {' at '}
          <Link href="https://www.maastrichtuniversity.nl/education/bachelor/bachelor-data-science-and-knowledge-engineering" external styled>Maastricht University</Link>
          {' after obtaining A-levels in Mathematics, Physics, Chemistry, and Biology from '}
          <Link href="http://www.ctc.ac.uk/" external styled>Cambridge Tutors College</Link>
          {' in London.'}
        </Paragraph>
        <Paragraph>
          In my free time, I like reading, tinkering and hacking.
        </Paragraph>
      </Content>
    );
  }
}

export default About;